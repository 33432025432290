$body-bg: #191d21;
$body-color: #d3d3d3;

$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

a {
    color: $body-color;
    text-decoration: none;

    &:hover {
        color: $primary;
        text-decoration: $link-hover-decoration;
    }
}

.toast {
    width: auto;
    display: inline-block;
}

.toast.fixed-bottom-right {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    overflow: hidden;
    max-width: calc(50% + 1rem);
}

.toast .toast-body {
    padding: 0.5rem 0.75rem;
}
.toast button[data-bs-dismiss="toast"] {
    font-size: 80%;
}

// // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// @import "../../node_modules/bootstrap/scss/functions";

// // 2. Include any default variable overrides here
// $body-bg: #191d21;
// $body-color: #d3d3d3;

// // 3. Include remainder of required Bootstrap stylesheets
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";
// @import "../../node_modules/bootstrap/scss/root";

// // 4. Include any optional Bootstrap CSS as needed
// @import "../../node_modules/bootstrap/scss/reboot";
// @import "../../node_modules/bootstrap/scss/utilities";
// @import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/containers";
// @import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/helpers";
